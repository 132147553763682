import React from 'react';
import { Dialog, Checkbox, FormHelperText, FormInput, FormLabel, FormPhoneInput } from '@ui/components/core';
import { useForm } from '@mantine/form';
import Image from 'next/image';
import { Button } from '../../buttons/Button/Button';
import cn from '@ui/utils/cn';
import useNewsLetterSignup from '@ui/hooks/useNewsletterSignup';
import { notifications } from '@mantine/notifications';
import env from '@ui/env';
import { type CountryCode } from 'libphonenumber-js/mobile';
import validatePhone from '@ui/helpers/validatePhone';
import { useStaticContent } from '@ui/providers/static-content-provider';

const DEFAULT_COUNTRY_CODE = env.NEXT_PUBLIC_REGION as CountryCode;
const DISMISSED_KEY = 'newsletterDialogDismissed';
const DISMISSED_VALUE = '1';

const NewsletterDialog = () => {
  const [open, setOpen] = React.useState(false);
  const { isLoading, data, error, submit } = useNewsLetterSignup();
  const smsBumpAccount = useStaticContent('Yotpo.SmsBumpAccount');
  const smsBumpListId = useStaticContent('Yotpo.SmsBumpListId');

  React.useEffect(() => {
    if (localStorage.getItem(DISMISSED_KEY) === DISMISSED_VALUE) return;
    const timeoutId = setTimeout(() => setOpen(true), 10000);
    return () => clearTimeout(timeoutId);
  }, []);

  React.useEffect(() => {
    if (!error) return;

    notifications.show({
      message: error,
      autoClose: 3000,
      withCloseButton: true,
      variant: 'danger',
    });
  }, [error]);

  const form = useForm({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      terms: false,
    },
    validate: {
      name: (value) => (value.length > 0 ? null : 'Name is required'),
      email: (value) => {
        if (value.length === 0) return 'Email is required.';
        if (!/^\S+@\S+$/.test(value)) return 'Invalid email format.';
        return null;
      },
      phone: (phone) => validatePhone(phone, DEFAULT_COUNTRY_CODE),
      terms: (value) => (value === false ? 'You must accept the terms and conditions' : null),
    },
  });

  return (
    <Dialog.Root
      open={open}
      onOpenChange={() => {
        setOpen(false);
        localStorage.setItem(DISMISSED_KEY, DISMISSED_VALUE);
      }}
    >
      <Dialog.Title className="sr-only">Newsletter Signup</Dialog.Title>
      <Dialog.Content
        classNames={{
          root: 'max-w-3xl bg-transparent rounded-none max-md:mb-0',
          close: cn(
            'top-1 right-1 size-8 bg-white rounded',
            'max-md:static max-md:border max-md:ml-auto max-md:border-black max-md:mb-2',
          ),
        }}
      >
        <div className="bg-black md:bg-black text-white border border-black rounded-md grid md:grid-cols-2">
          <div className="relative max-md:hidden">
            <Image
              src="https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Pop_Up__2-Retouched.jpg?v=1731016463"
              alt=""
              width="1912"
              height="2868"
              className="h-full w-full object-cover absolute object-top rounded-l"
              loading="eager"
            />
          </div>
          <div className="p-4 md:p-6">
            {!data ? (
              <div>
                <div>
                  <div className="uppercase tracking-[0.06em]">Black Friday is almost here!</div>

                  <div className="text-xl font-semibold tracking-[0.04em] uppercase mt-2 md:mt-4">
                    Sign up to be notified
                    <br />
                    first about our sale
                  </div>

                  {/* <div className="uppercase tracking-[0.06em] text-sm mt-2.5">on your next purchase</div> */}
                </div>

                <form
                  noValidate
                  className="mt-6"
                  onSubmit={form.onSubmit((values, event) => {
                    event.preventDefault();

                    const partsOfName = values.name
                      .split(' ')
                      .map((part) => part.trim())
                      .filter(Boolean);

                    submit({
                      email: values.email,
                      firstName: partsOfName.length > 2 ? partsOfName.slice(0, -1).join(' ') : partsOfName[0],
                      lastName: partsOfName.length > 1 ? partsOfName[partsOfName.length - 1] : undefined,
                      phone: values.phone,
                      smsBump: {
                        account: smsBumpAccount,
                        listId: smsBumpListId,
                        source: 'newsletter_popup',
                      },
                    });
                  })}
                >
                  <div>
                    <FormLabel htmlFor="newsletterSignupModalName" required>
                      Name
                    </FormLabel>

                    <FormInput
                      {...form.getInputProps('name', { withError: false })}
                      invalid={Boolean(form.errors.name)}
                      autoComplete="name"
                      id="newsletterSignupModalName"
                      name="name"
                      type="text"
                      required
                      className="text-black"
                    />

                    {form.errors.name && (
                      <FormHelperText className="mt-1" invalid>
                        {form.errors.name}
                      </FormHelperText>
                    )}
                  </div>

                  <div className="mt-4">
                    <FormLabel htmlFor="newsletterSignupModalEmail" required>
                      Email
                    </FormLabel>

                    <FormInput
                      {...form.getInputProps('email', { withError: false })}
                      invalid={Boolean(form.errors.email)}
                      autoComplete="email"
                      id="newsletterSignupModalEmail"
                      name="email"
                      type="text"
                      required
                      className="text-black"
                    />

                    {form.errors.email && (
                      <FormHelperText className="mt-1" invalid>
                        {form.errors.email}
                      </FormHelperText>
                    )}
                  </div>

                  <div className="mt-4">
                    <FormLabel htmlFor="createAddressPhone">Phone</FormLabel>

                    <FormPhoneInput
                      {...form.getInputProps('phone', { withError: false })}
                      onChange={(newValue) => form.setFieldValue('phone', newValue)}
                      invalid={Boolean(form.errors.phone)}
                      defaultCountryCode={DEFAULT_COUNTRY_CODE}
                      id="createAddressPhone"
                      name="phone"
                      type="text"
                      className="text-black"
                    />

                    {form.errors.phone && (
                      <FormHelperText className="mt-1" invalid>
                        {form.errors.phone}
                      </FormHelperText>
                    )}
                  </div>

                  <div className="mt-4">
                    <Checkbox
                      checked={form.values.terms}
                      onChange={(e) => form.setFieldValue('terms', e.target.checked)}
                      label="I consent to receive promotional communications related to my purchase history, website browsing activity, and/or engagement with marketing materials."
                      size="xs"
                      styles={() => ({
                        label: {
                          color: 'white',
                        },
                      })}
                    />

                    {form.errors.terms && (
                      <FormHelperText className="mt-1" invalid>
                        {form.errors.terms}
                      </FormHelperText>
                    )}
                  </div>

                  <Button type="submit" className="w-full mt-4" loading={isLoading} variant="secondary">
                    SIGN UP
                  </Button>
                </form>
              </div>
            ) : (
              <div className="min-h-[455px] md:min-h-[500px]">
                <div className="text-xl tracking-[0.25px] font-semibold uppercase">Welcome to Hello Molly</div>
                <div className="uppercase text-sm tracking-[0.6px] leading-loose mt-2.5">
                  Enter this <b>special code</b> at checkout for <b>10% off</b> your order.
                </div>
                <div className="text-sm tracking-[0.6px] leading-loose mt-1.5">
                  We&apos;ve also sent it to your email.
                </div>
                <div className="text-xl tracking-[0.25px] font-semibold uppercase mt-2.5">Code: SIGNUPSQUAD</div>
                <div className="text-xs uppercase mt-1.5">Happy shopping!</div>
              </div>
            )}
          </div>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default NewsletterDialog;
