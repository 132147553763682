import React from 'react';
import Marquee from 'react-fast-marquee';
import { Container, Group } from '@ui/components/core';
import { BannerCurrencyDropdown, LocalCurrencyDropdown } from '@ui/components/features';
import Link from 'next/link';
import env from '@ui/env';
import { StaticContent } from '@ui/providers';
import { Text } from '@ui/components/shared/typography';

const BannerBFCM = ({ isCanada }: { isCanada?: boolean }) => {
  return (
    <div
      className="border-b-[0.6px] border-b-black bg-black flex items-center h-[--banner-height]"
      data-test="usp-banner"
    >
      <Container className="px-4 md:px-8">
        <div className="flex items-center space-x-4">
          <div className="flex-1 overflow-hidden">
            <Marquee>
              <Group spacing="4rem" mr="4rem">
                {Array.from(Array(2).keys()).map((iteration, index) => (
                  <React.Fragment key={iteration}>
                    <Link
                      href="/account/signup"
                      className="tracking-[0.04em] hover:underline underline-offset-4 text-white"
                    >
                      <p className="text-sm font-bold uppercase">Get Black Friday Early Access! Sign Up Now</p>
                    </Link>
                    <Link href="/pages/shipping" className="hover:underline underline-offset-4 text-white">
                      <Text sx={{ whiteSpace: 'nowrap' }} className="text-sm  tracking-[0.04em]">
                        <StaticContent id="Banner.FreeShipping" />
                      </Text>
                    </Link>

                    {isCanada && (
                      <Link
                        href="/collections/concert-era"
                        className="text-sm text-white tracking-[0.04em] hover:underline underline-offset-4"
                      >
                        Enter Your <b>Concert Era</b>! All orders get a <b>FREE</b> friendship bracelet! While stocks
                        last. <b>SHOP NOW &gt;</b>
                      </Link>
                    )}
                  </React.Fragment>
                ))}
              </Group>
            </Marquee>
          </div>
          {!env.MULTICURRENCY_FEATURE && <BannerCurrencyDropdown className="text-white" />}
          {env.MULTICURRENCY_FEATURE && <LocalCurrencyDropdown className="text-white" />}
        </div>
      </Container>
    </div>
  );
};

export default BannerBFCM;
