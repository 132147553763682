import { Button } from '@ui/components/shared';
import env from '@ui/env';
import { Modal } from '@mantine/core';
import React from 'react';
import { useQuery } from '@tanstack/react-query';

const OPEN_DELAY_MS = 1000;

type StoreRegion = typeof env.NEXT_PUBLIC_REGION;
// 'GB', add 'GB" to the STORES array if you want to enable the UK store
const STORES: Array<StoreRegion> = ['AU', 'US', 'NZ'];

const countryConfig = {
  US: {
    title: 'SHOPPING FROM USA?',
    description: 'CONTINUE TO OUR USA SITE FOR:',
    perks: ['Free shipping over USD$100', 'Shop in US Dollars', 'Same day or standard delivery options'],
    siteLink: 'https://www.hellomolly.com/',
    siteName: 'USA',
  },
  GB: {
    title: 'SHOPPING FROM UK?',
    description: 'CONTINUE TO OUR UK SITE FOR:',
    perks: ['Shop in GBP'],
    siteLink: 'https://hellomolly.co.uk/',
    siteName: 'UK',
  },
  AU: {
    title: 'SHOPPING FROM AUSTRALIA?',
    description: 'CONTINUE TO OUR AUSTRALIAN SITE FOR:',
    perks: ['Free shipping over AUD$100', 'Shop in AU Dollars', 'Express shipping from our Sydney HQ'],
    siteLink: 'https://hellomolly.com.au/',
    siteName: 'AU',
  },
  NZ: {
    title: 'SHOPPING FROM NEW ZEALAND?',
    description: 'CONTINUE TO OUR NEW ZEALAND SITE FOR:',
    perks: ['Free shipping over NZD$150', 'Shop in NZ Dollars'],
    siteLink: 'https://hellomolly.co.nz/',
    siteName: 'NZ',
  },
};

const StoreLocator = () => {
  const [openModal, setOpenModal] = React.useState(false);
  const [hasAlternateStore, setHasAlternateStore] = React.useState(false);

  const { data } = useQuery({
    queryKey: ['customerCountry'],
    queryFn: () =>
      (async () => {
        const res = await fetch(env.NEXT_PUBLIC_BASE_URL + '/api/location');
        const data = await res.json();
        setHasAlternateStore(STORES.includes(data.country) && data.country !== env.NEXT_PUBLIC_REGION);
        return data;
      })(),
  });

  React.useEffect(() => {
    const hasConfirmedLocation = localStorage.getItem('confirmLocation') === '1';
    if (hasConfirmedLocation || !hasAlternateStore) return;
    const timeoutId = setTimeout(() => setOpenModal(true), OPEN_DELAY_MS);
    return () => clearTimeout(timeoutId);
  }, [hasAlternateStore, data]);

  function handleClose() {
    localStorage.setItem('confirmLocation', '1');
    setOpenModal(false);
  }

  if (!env.STORE_LOCATOR_FEATURE) {
    return null;
  }

  if (!hasAlternateStore) {
    return null;
  }

  const countryDetails = countryConfig[data.country as keyof typeof countryConfig];

  if (!countryDetails) {
    return null; // No country config found, return nothing.
  }

  return (
    <>
      {hasAlternateStore && (
        <Modal
          size={464}
          opened={openModal}
          onClose={() => setOpenModal(false)}
          keepMounted={false}
          withCloseButton={false}
          classNames={{
            overlay: 'bg-black/20',
            inner: 'items-end md:items-center px-4 py-7',
            content: 'border border-black rounded-lg bg-[#FCF3F6]',
            body: 'p-4 md:p-8',
          }}
        >
          <div className="space-y-6">
            <div className="space-y-4">
              <div className="space-y-3">
                {countryDetails.title && (
                  <div className="text-[20px] leading-6 tracking-[0.25px] font-normal">{countryDetails.title}</div>
                )}
                <div className="tracking-[0.006em] leading-[19.2px]">{countryDetails.description}</div>
              </div>
              <ul className="list-disc ml-5">
                {countryDetails.perks.map((perk, index) => (
                  <li key={index} className="text-[14px] tracking-[0.6px] leading-[26.04px]">
                    {perk}
                  </li>
                ))}
              </ul>
            </div>
            <div className="space-y-2">
              <Button className="w-full" asChild>
                <a href={countryDetails.siteLink}>GO TO OUR {countryDetails.siteName} SITE</a>
              </Button>
              <Button onClick={handleClose} className="w-full" variant="tertiary">
                STAY ON THE {`${env.NEXT_PUBLIC_REGION}`} SITE
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default StoreLocator;
