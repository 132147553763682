import React from 'react';
import { useRouter } from 'next/router';
import { IconMinus, IconPlus } from '@tabler/icons-react';
import { NumberInputHandlers } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useCartRemove, useCartUpdate } from '@ui/hooks/useCartMutations';

import { ActionIcon, Badge, CompactCloseIcon, NextLink, NumberInput } from '@ui/components/core';
import useCartStore from '@ui/store/cartStore';
import env from '@ui/env';
import FinalSaleModal from '../../modals/FinalSaleModal/FinalSaleModal';
import Image from 'next/image';
import cn from '@ui/utils/cn';
import Link from 'next/link';
import { GetCart } from '@client-shopify/gql/storefront/api/queries/GetCart';
import { useElevarDataLayer } from '@ui/hooks/useElevarDataLayer';
import Money from '../../formatters/Money/Money';

type CartProductCardProps = {
  product: Exclude<
    Exclude<Awaited<ReturnType<typeof GetCart>>, { cart: undefined } | null>['cart']['cartLines'],
    undefined
  >[number];
  onClose?: () => void;
  classNames?: {
    root?: string;
    image?: string;
    details?: string;
    title?: string;
  };
};

const CartProductCard = ({ product, onClose, classNames }: CartProductCardProps): React.ReactElement => {
  const router = useRouter();
  const { elevarAddToCartEvent, elevarRemoveFromCartEvent } = useElevarDataLayer();
  const cartId = useCartStore((state) => state?.cartId);
  const cartUpdatedKey = useCartStore((state) => state?.cartUpdatedKey);
  const setCartUpdatedKey = useCartStore((state) => state?.setCartUpdatedKey);
  const [finalSaleModalOpened, { toggle: finalSaleModalOpen, close: finalSaleModalClose }] = useDisclosure(false);
  const [currentProductQuantity, setCurrentProductQuantity] = React.useState(product?.quantity);
  const handler = React.useRef<NumberInputHandlers>();
  const maxQuantity = product.handle.includes('giftcard') ? Infinity : product.quantityAvailable;

  const { cartLinesUpdateMutation, cartLineUpdateLoading, cartLineUpdateError } = useCartUpdate();

  const { cartLinesRemoveMutation, cartRemoveError, cartRemoveLoading } = useCartRemove();

  React.useEffect(() => {
    setCurrentProductQuantity(product.quantity);
  }, [product.quantity, cartUpdatedKey]);

  const quantity = React.useRef(currentProductQuantity || 1);

  const removeFromCart = async () => {
    elevarRemoveFromCartEvent(
      [
        {
          image: product.featuredImage.url,
          brand: 'brand',
          category: 'category',
          id: product.variantId,
          compare_at_price: product.costPerQuantity.amount.toString(),
          list: 'cart',
          name: product.title,
          position: 1,
          price: product.costPerQuantity.amount.toString(),
          product_id: product.productId.split('/').at(-1) ?? '',
          quantity: '1',
          variant: product.variantId.split('/').at(-1) ?? '',
          variant_id: product.variantId.split('/').at(-1) ?? '',
        },
      ],
      router.asPath,
    );
    setFadeOut(true);
    try {
      await cartLinesRemoveMutation({
        cartId: cartId as string,
        cartLineIds: [product.id],
      });
      if (cartRemoveError) {
        throw new Error(cartRemoveError.message);
      }
      setCartUpdatedKey?.();
    } catch (err) {
      throw new Error(JSON.stringify(err));
    }
  };

  const updateCart = (action?: string): void => {
    if (action === 'minus' && currentProductQuantity === 1) {
      removeFromCart();
      return;
    }
    cartLinesUpdateMutation({
      cartId: cartId as string,
      cartUpdateInput: {
        quantity: quantity.current,
        id: product.id,
      },
    }).then((result) => {
      if (cartLineUpdateError) {
        throw new Error(cartLineUpdateError.message);
      }

      if (result?.cart && result?.cart.cartLines && result?.cart.cartLines.length > 0) {
        const cartUpdateProduct = result.cart.cartLines.find(
          (cartProduct) => cartProduct.variantId === product.variantId,
        );

        if (cartUpdateProduct) {
          elevarAddToCartEvent(
            [
              {
                url: product.handle,
                image: product.featuredImage.url,
                brand: 'brand',
                category: 'category',
                id: product.sku ?? '',
                compare_at_price: product.costPerQuantity.amount.toString(),
                list: 'cart',
                name: product.title,
                position: 1,
                price: product.costPerQuantity.amount.toString(),
                product_id: product.productId.split('/').at(-1) ?? '',
                quantity: quantity.current.toString(),
                variant: product?.sku?.split('-').at(-1) ?? '',
                variant_id: product.variantId.split('/').at(-1) ?? '',
              },
            ],
            router.asPath,
          );
          setCartUpdatedKey?.();
        }
      }
    });
  };

  const [fadeOut, setFadeOut] = React.useState(false);

  return (
    <div
      className={cn(
        env.CART_DRAWER_PREVIEW
          ? 'flex space-x-4 md:space-x-5 transition duration-500 ease-in-out'
          : 'flex space-x-4 transition duration-500 ease-in-out',
        fadeOut ? 'opacity-0' : 'opacity-100',
        classNames?.root,
      )}
    >
      <Link
        href={`/products/${product.handle}`}
        onClick={onClose}
        className={cn(
          'block relative aspect-[88/132] overflow-hidden',
          env.CART_DRAWER_PREVIEW && 'w-[71px] md:w-[88px]',
          classNames?.image,
        )}
      >
        <Image
          className={cn('self-start shrink-0 w-full !h-auto object-cover object-center')}
          src={product.featuredImage.url}
          alt={product.featuredImage.altText || 'Hello Molly'}
          fill
        />
      </Link>
      <div className={cn('w-full flex flex-col justify-between space-y-1', classNames?.details)}>
        <div>
          <div className="flex items-start space-x-2">
            <NextLink
              href={`/products/${product.handle}`}
              className={cn('font-bold text-[13px] lg:text-[14px] uppercase flex-1', classNames?.title)}
              onClick={onClose}
            >
              {product.title}
            </NextLink>
            <ActionIcon variant="transparent" size="sm" onClick={removeFromCart}>
              <CompactCloseIcon height={10} width={10} />
            </ActionIcon>
          </div>
          {!product.handle.includes('giftcard') && (
            <div className="text-[10px] lg:text-xs uppercase mt-1">Size: {product.size}</div>
          )}
          {product.tags.includes('final-sale') && (
            <Badge
              color="red.9"
              bg="#FF0D00"
              variant="filled"
              radius="xs"
              px="0.25rem"
              className="px-1 mt-2"
              onClick={finalSaleModalOpen}
            >
              FINAL SALE
            </Badge>
          )}
        </div>

        <div className="flex items-end justify-between">
          <div className="border border-[#757575] rounded-[4px] flex items-center h-8 overflow-hidden">
            <ActionIcon
              data-test="cart-minus-button-test"
              variant="transparent"
              disabled={cartLineUpdateLoading || cartRemoveLoading}
              onClick={() => {
                handler.current?.decrement();
                updateCart('minus');
              }}
            >
              <IconMinus size="1rem" stroke={1.5} />
            </ActionIcon>
            <NumberInput
              min={0}
              max={maxQuantity ?? undefined}
              hideControls
              value={currentProductQuantity}
              disabled={cartLineUpdateLoading || cartRemoveLoading}
              onChange={(val) => {
                if (val === '') {
                  quantity.current = 1;
                } else {
                  quantity.current = val;
                }
              }}
              onBlur={() => updateCart()}
              handlersRef={handler}
              classNames={{
                input: 'text-[13px] text-[#727272] text-center w-4 h-auto p-0 border-none focus:border-none',
              }}
            />
            <ActionIcon
              variant="transparent"
              data-test="cart-plus-button-test"
              disabled={maxQuantity === quantity.current || cartLineUpdateLoading || cartRemoveLoading}
              onClick={() => {
                handler.current?.increment();
                updateCart();
              }}
            >
              <IconPlus size="1rem" stroke={1.5} />
            </ActionIcon>
          </div>
          <div className="text-xs lg:text-[13px] font-normal">
            <Money
              value={(parseFloat(product.costPerQuantity.amount.toString()) * quantity.current).toFixed(2)}
              currency={product.costPerQuantity.currencyCode}
            />
          </div>
        </div>
      </div>
      <FinalSaleModal opened={finalSaleModalOpened} onClose={finalSaleModalClose} />
    </div>
  );
};

export default CartProductCard;
