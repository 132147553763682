export const emarsysInnerScarabScriptFunc = `
  (function(id) {
    if (document.getElementById(id)) return;
    var js = document.createElement('script'); js.id = id;
    js.src = '//cdn.scarabresearch.com/js/17309B80C7260A5C/scarab-v2.js';
    var fs = document.getElementsByTagName('script')[0];
    fs.parentNode.insertBefore(js, fs);
  })('scarab-js-api');
`;

export const emarsysInnerScriptFunc = `var WebEmarsysSdk = WebEmarsysSdk || [];
        var WebEmarsysSdk = WebEmarsysSdk || [];
        WebEmarsysSdk.push(['init', {
          enableLogging: true,
          applicationCode: 'EMS45-17971',
          safariWebsitePushID: 'web.com.hellomolly.www',
          defaultNotificationTitle: 'Hello Molly',
          defaultNotificationIcon: 'https://link-us.hellomolly.com/custloads/1049686944/md_20407.png',
          autoSubscribe: false,
          serviceWorker: {
            url: 'apps/ems/web-emarsys-sdk/4.3.1/service-worker.js',
            applicationServerPublicKey: 'BKXuOtIPqSlyixnIbSLjrov19Vo14LmZr63vMftVoiMI5Y8BS9oC-rGmZHBpbvGTp77ZkKE19K9MOkZZu2Zb734'
          }
        }])
`;
