import React from 'react';
import { NewsletterForm } from '../../../shared';
import Image from 'next/image';
import NewsletterModal from '@ui/components/shared/modals/NewsletterModal/NewsletterModal';
import { useDisclosure } from '@mantine/hooks';

const NewsletterSection = (): React.ReactElement => {
  const [modalOpened, { open: openModal, close: closeModal }] = useDisclosure();

  return (
    <div className="border-y-[0.6px] border-black relative isolate">
      <div>
        <Image
          src="https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Sign_up_banner-desktop.jpg?v=1716856080"
          className="w-full h-full object-cover object-top absolute"
          width="2880"
          height="350"
          alt="Newsletter Banner Background"
        />
        <div className="absolute h-full w-1/2 bg-gradient-to-r from-white to-transparent"></div>
      </div>
      <div className="md:hidden">
        <Image
          src="https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Sign_up_banner-mobile.jpg?v=1716856142"
          alt="Newsletter Banner Background"
          className="w-full h-full object-cover object-top absolute"
          width="2880"
          height="350"
        />
        <div className="absolute h-[71%] w-full bottom-0 bg-gradient-to-t from-white to-transparent"></div>
      </div>
      <div className="relative mx-auto w-full max-w-[34rem] px-4 py-8">
        <div className="font-bold mb-4 tracking-[0.08px] text-center md:text-left">SIGN UP FOR 10% OFF</div>
        <NewsletterForm onSuccess={openModal} />
        <NewsletterModal opened={modalOpened} onClose={closeModal} />
      </div>
    </div>
  );
};

export default NewsletterSection;
