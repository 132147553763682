import useCustomerLocation from '@ui/hooks/useCustomerLocation';
import Link from 'next/link';
import React from 'react';

export default function LinkToHome(props: Omit<React.ComponentProps<typeof Link>, 'ref' | 'href'>) {
  const customerLocation = useCustomerLocation();

  /** @todo post-sale */
  return <Link href={customerLocation?.country === 'CA' ? '/canada' : '/'} {...props} />;
}
