import { z } from 'zod';

const envSchema = z.object({
  NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN: z.string().regex(/[\w\-]+\.myshopify\.com/), // e.g. hellomollyfashion-nz.myshopify.com
  NEXT_PUBLIC_SHOPIFY_GRAPHQL_API_VERSION: z.string().regex(/\d\d\d\d-\d\d/), // e.g. 2023-01
  NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN: z.string().min(1),
  NEXT_PUBLIC_BLOG_ACCESS_ENDPOINT: z.string().regex(/[\w\-]+\.myshopify\.com/),
  NEXT_PUBLIC_BLOG_ACCESS_TOKEN: z.string().min(1),
  NEXT_PUBLIC_REGION: z.enum(['AU', 'NZ', 'US', 'GB']),
  FREE_SHIPPING_AMOUNT: z.number(),
});

const env = envSchema.parse({
  NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN,
  NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN: process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
  NEXT_PUBLIC_SHOPIFY_GRAPHQL_API_VERSION: process.env.NEXT_PUBLIC_SHOPIFY_GRAPHQL_API_VERSION,
  NEXT_PUBLIC_BLOG_ACCESS_ENDPOINT:
    process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN === 'US'
      ? 'hellomollyfashion-usa.myshopify.com'
      : 'hellomollyfashion.myshopify.com',
  NEXT_PUBLIC_BLOG_ACCESS_TOKEN: process.env.NEXT_PUBLIC_BLOG_ACCESS_TOKEN,
  NEXT_PUBLIC_REGION: process.env.NEXT_PUBLIC_REGION,
  FREE_SHIPPING_AMOUNT: ['AU', 'US'].includes(process.env.NEXT_PUBLIC_REGION as string) ? 100.0 : 150.0,
});

export default env;
