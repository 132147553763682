export const GiftCardTemplates = [
  {
    label: "Every Gal's Dream Gift",
    code: 'HM-Gift-Card-Dream',
    color: '#fedce8',
    image_url: 'https://assets.hellomolly.com/banner/giftcards/HM-Gift-Card-Dream.png',
    preview_url: 'https://assets.hellomolly.com/banner/giftcards/HM-Gift-Card-Dream-trimmed.webp',
    icon_url: 'https://assets.hellomolly.com/banner/giftcards/HM-Gift-Card-Dream-icon.png',
  },
  {
    label: 'The Gift of Hot New Fits',
    code: 'HM-Gift-Card-Hot',
    color: '#ff88d5',
    image_url: 'https://assets.hellomolly.com/banner/giftcards/HM-Gift-Card-Hot.png',
    preview_url: 'https://assets.hellomolly.com/banner/giftcards/HM-Gift-Card-Hot-trimmed.webp',
    icon_url: 'https://assets.hellomolly.com/banner/giftcards/HM-Gift-Card-Hot-icon.png',
  },
  {
    label: "Babe You're Going to Slay",
    code: 'HM-Gift-Card-Slay',
    color: '#fe97ca',
    image_url: 'https://assets.hellomolly.com/banner/giftcards/HM-Gift-Card-Slay.png',
    preview_url: 'https://assets.hellomolly.com/banner/giftcards/HM-Gift-Card-Slay-trimmed.webp',
    icon_url: 'https://assets.hellomolly.com/banner/giftcards/HM-Gift-Card-Slay-icon.png',
  },
];

export const GiftCardDenominations = [
  { variantId: 'gid://shopify/ProductVariant/48723362414898', value: '25' },
  { variantId: 'gid://shopify/ProductVariant/48723392332082', value: '50' },
  { variantId: 'gid://shopify/ProductVariant/48723392430386', value: '100' },
  { variantId: 'gid://shopify/ProductVariant/48723392659762', value: '150' },
  { variantId: 'gid://shopify/ProductVariant/48723392790834', value: '200' },
  { variantId: 'gid://shopify/ProductVariant/48723393020210', value: '250' },
  { variantId: 'gid://shopify/ProductVariant/48723393151282', value: '300' },
  { variantId: 'gid://shopify/ProductVariant/48723393708338', value: '350' },
  { variantId: 'gid://shopify/ProductVariant/48723393839410', value: '400' },
  { variantId: 'gid://shopify/ProductVariant/48723394068786', value: '450' },
  { variantId: 'gid://shopify/ProductVariant/48723394560306', value: '500' },
];
